<template>
  <div>
    <Newheader />
    <div class="flex-col page">
      <div class="flex-col">
        <div class="flex-col">
          <div class="flex-col section_1">
            <div class="flex-col items-end group_2">
              <img src="@/assets/images/ionxtop.png" style="width: 100vw;height:100vh;object-fit: cover;" alt="">
            </div>
            <div class="flex-row justify-between section_3">
              <span data-aos="fade-up" class="text_5">KYMCO i-OneX</span>
              <div class="flex-row">
                <div data-aos="fade-up" class="flex-col items-center text-wrapper"><span class="text_6" @click="navPath('/drive')" style="cursor: pointer">预约试驾</span>
                </div>
                <div data-aos="fade-up" class="flex-col items-center text-wrapper_1"><a href="https://detail.tmall.com/item.htm?abbucket=6&id=645362303900&rn=ea0280898e20b6115ee7f2229564ad0a&spm=a1z10.3-b-s.w4011-23636442313.84.61f661edT82Rij&sku_properties=8369595:5557472"><span class="text_7" style="cursor: pointer">购买商城</span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-col items-center section_4">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361022571257185.png"
              class="image_3" />
            <div data-aos="fade-up" class="group_5">
              <span class="text_8">移动生活美学</span>
              <span class="text_9">从i-OneX启程</span>
              <br>
              <span class="text_10"></span>
              <span class="text_11">纯正摩托血统淬炼优异性能</span>
              <span class="text_12"></span>
              <span class="text_13">城市，就是我的主场</span>
            </div>
          </div>
          <div class="flex-col section_5">
            <video id="index_video" class="image_36"
          src="@/assets/video/ionex/1.mp4"
          style="object-fit: cover; width: 100%; height: 100%;position: absolute;left: 0;" x5-playsinline="true" webkit-playsinline="true"
          playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-ignore-metadata="true"
          autoplay="" muted="" loop=""></video>
            <div class="flex-col section_6" style="position: relative;z-index:10;">
              <span data-aos="fade-up" class="text_14">定义全新未来风潮</span>
              <div data-aos="fade-up" class="flex-row justify-between group_6">
                <div class="flex-col items-center">
                  <div>
                    <countTo class="text_15" :startVal='0' :endVal='65' :decimals="0" :duration='5000'></countTo>
              <span class="text_16">KM</span>
                  
                  </div>
                  <span class="text_17">等速续航</span>
                </div>
                <div class="flex-col items-center group_9">
                  <div>
                    <countTo class="text_18" :startVal='0' :endVal='49' :decimals="0" :duration='5000'></countTo>
              <span class="text_19">KM/H</span>
                    
                  </div>
                  <span class="text_20">极速</span>
                </div>
                <div class="flex-col items-center group_11">
                  <div>
                    <countTo class="text_21" :startVal='0' :endVal='1.5' :decimals="1" :duration='5000'></countTo>
              <span class="text_22">KW</span>
                   
                  </div>
                  <span class="text_23">最大功率</span>
                </div>
                <div class="flex-col items-center group_13">

                  <countTo class="text_24" :startVal='0' :endVal='21700' :decimals="0" :duration='5000'></countTo>
              <span class="text_25">电芯规格</span>
                 
                </div>
              </div>
              <div @click="play(require('@/assets/video/ionex/2.mp4'))" data-aos="fade-up" class="flex-row justify-center section_7">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279462540644809.png"
                  class="image_6" />
                <span class="text_26 cur">观看完整影片</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col items-end section_8">
          <div data-aos="fade-up" class="group_14">
            <div class="text_27">耐看型选手</div>
            <div class="text_28">颜值始终在线</div>
          </div>
          <div data-aos="fade-up" class="group_15">
            <div class="text_29">富有韵律的线条有节奏地交错流动</div>
            <div class="text_30">构建成参数化肌理前面板，车规级LED标志灯</div>
            <div class="text_31">给人耳目一新的未来感</div>
          </div>
        </div>
      </div>
      <div class="flex-row group_16">
        <div data-aos="fade-up" class="flex-col items-start section_9">
          <div data-aos="fade-up">
            <span class="text_32" style="font-family: Albbr;">留下</span>
            <span class="text_33" style="font-family: Albbr;">帅气的背影</span>
          </div>
          <span data-aos="fade-up" class="text_34" style="font-family: Albbr;">
            反曲面腰身设计衔接大溜背尾部轮廓，搭配熔岩科技尾灯组，为车身营造更具科技感的视觉感受，潮流气场再度升级。
          </span>
        </div>
        <div data-aos="fade-up" class="flex-col items-start section_10">
          <div data-aos="fade-up">
            <span class="text_35" style="font-family: Albbr;">做足</span>
            <span class="text_36" style="font-family: Albbr;">“表面功夫”</span>
          </div>
          <span data-aos="fade-up" class="text_37" style="font-family: Albbr;">
            全车喷涂的汽车级环保水性漆具有耐划伤 、耐污染 、超耐UV光照老化的特点，色泽华丽柔和持久如新。
          </span>
        </div>
      </div>
      <span data-aos="fade-up" class="text_38">只能自己体会的细节</span>
      <div class="flex-row group_19">
        <!-- <img data-aos="fade-up" -->
          <!-- src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361017918089007.png" -->
          <!-- class="image_5 image_8" /> -->
        <img data-aos="fade-up"
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361017007172280.png"
          class="image_5 image_9 cur" style="width:60px;height: 60px;"/>
        <div class="flex-row justify-center group_20">

          <div data-aos="fade-up" class="swiper swiper1" style="overflow:visible;">
            <div class="swiper-wrapper">
              <div v-for="(t,i) in list" class="swiper-slide">
                <div class="flex-col items-start section_11">
                  <img :src="require(`@/assets/images/ionex/${i+1}.png`)" class="image_7" />
                  <div class="flex-col group_51">
                    <span class="text_39" v-html="t.title"></span>
                    <div class="group_21">
                      <span class="text_40" v-html="t.content"></span>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>




        </div>
      </div>
      <img data-aos="fade-up"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361014665439124.png"
        class="image_10" />
      <span data-aos="fade-up" class="text_47">12项车规级技术增程锂电池</span>
      <div class="flex-col group_24">
        <div data-aos="fade-up" class="flex-row justify-between group_25">
          <span class="text_48">面面俱到的安全保护</span>
          <span class="text_49" style="margin-left:-30px;">值得一提的内在功夫</span>
          <div class="flex-row" style="margin-right:20px">
            <span class="text_50">无限续航</span>
            <span class="text_51">即刻焕新</span>
          </div>
        </div>
        <div data-aos="fade-up" class="flex-row justify-between group_27">
          <div class="group_28">
            <span class="text_52">具备IP67的防水等级</span>
            <span class="text_53">当电池温度过高时，主动热失控检测防护系统即刻自动断电</span>
          </div>
          <div class="group_29">
            <span class="text_54">锂电芯呈蜂巢式排列搭配</span>
            <span class="text_56">保证高导电效率的同时还将获得</span>
            <span class="text_58">单体电芯熔丝保护</span>
          </div>
          <div class="group_29">
            <span class="text_54">全系标配租电服务，缓解里程焦虑</span>
            <span class="text_56">在换电站每颗更换的电池都是健康的<br>无需承担电池损耗寿命</span>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" class="flex-col items-center text-wrapper_2"><span class="text_61"  @click="navPath('/ionex')">了解车能网</span></div>
      <div class="flex-col group_30">
        <div data-aos="fade-up" class="swiper swiper2">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="flex-col section_14">
                <div class="flex-col">
                  <div class="flex-col group_32">
                    <span class="text_62">大材小用，各尽骑用</span>
                    <div class="flex-col items-center group_33">
                      <img
                        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361009863604148.png"
                        class="image_11" />
                      <div class="group_34">
                        <span class="text_63">每个车部件都来自摩企60年制造工艺沉淀，全面应用最新智能制造系统，</span>
                        <span class="text_64">从零件到整车，都在机器人工作站的标准化监控下作业，高效且有品质保障。</span>
                      </div>
                    </div>
                    <span class="text_65">环抱式车架结构</span>
                  </div>
                  <div class="text_66" style="text-align:center"> KYMCO大排量踏板摩托车同材铬钼钢管，经过160万次应力及SHOCK测试， <br/>车身具备更大的强度和刚性。
                  </div>
                  <!-- <span class="text_66">
         
        </span> -->
                </div>
                <div class="flex-row justify-center group_35">
                  <div class="section_15" @click="sclick(0)">
                    <!--*-->
                  </div>
                  <div class="section_16" @click="sclick(1)">
                    <!--*-->
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <ionex></ionex>
            </div>
          </div>

        </div>



        <div class="flex-row">

          <constart car="ionex"></constart>
        </div>
        <div class="flex-col section_18">
          <div class="flex-row justify-between group_40">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316208115921.png"
              class="image_19" />
            <div class="flex-row group_41">
              <span class="text_71"  @click="navPath('/drive')">预约试驾</span>
              <span class="text_73">工作后台</span>
            </div>
          </div>
          <div class="flex-col group_42">
            <div class="flex-row justify-between">
              <div class="flex-col group_44">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316223612676.png"
                  class="image_20" />
                <div class="group_45">
                  <span class="text_74">营销电话: 0519-68197852</span>
                  <span class="text_75">服务热线: 400-808-0395</span>
                  <span class="text_76">企业邮箱: ev@mail.kymco.com.cn</span>
                </div>
              </div>
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316215117664.png"
                class="image_21" />
            </div>
            <span class="text_77">光阳摩托 / ionex台湾 / 光陽臺灣 / KYMCO Global</span>
            <div class="flex-row justify-between group_46">
              <div class="group_47">
                <span class="text_78">Copyright © 2022 杭州英捷鑫科技有限公司&ensp;&ensp;All rights reserved</span>
                <span class="text_79">.</span>
                <span class="text_80">&ensp;&ensp;浙ICP备20029190号</span>
                <br/>
                <br/>
                <span class="text_81">Design by</span>
                <a href="http://www.hansn.cn/">
                  <span class="text_82">HANSN</span>
                </a>
              </div>
              <div class="flex-row group_48">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016330808267248.png"
                  class="image_29 image_30" />
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016330762903778.png"
                  class="image_24" />
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316224251993.png"
                  class="image_25" />
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316223373224.png"
                  class="image_26" />
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316221464315.png"
                  class="image_29" />
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316224204316.png"
                  class="image_28" />
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316226406954.png"
                  class="image_29" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ionex from "../components/ionex.vue"
import constart from "../components/constart/index.vue"
import countTo from 'vue-count-to';
export default {
  name: 'Vue3Ionex',
  components: { ionex, constart,countTo },
  data() {
    return {
      mySwiper: "",
      sindex: 0,
      sjarr: [
        {
          text: "雾海蓝",
          color: "#89bffa",
          url: require('@/assets/images/ionex/sj/1.png')
        },

        {
          text: "莫兰迪粉",
          color: "#f6c2c7",
          url: require('@/assets/images/ionex/sj/2.png')
        },
        {
          text: "熔岩红,时光白",
          color: "#eb3330,#a3a3a3",
          url: require('@/assets/images/ionex/sj/3.png')
        },
        {
          text: "暗夜黑",
          color: "#191919",
          url: require('@/assets/images/ionex/sj/4.png')
        },
      ],
      list: [
        {
          title: "数码微动开关", content: `键程长短适中反馈清晰舒适<br>
直视前方也可清晰操作功能键`},
        {
          title: "自动感应大灯", content: `随夜幕降临自动闪耀<br>
每天回家的路始终清楚明亮`}, {
          title: "全新升级多彩智能仪表",
          content: "精准到每一位数，更智能更数字化"

        },
        {
          title: "摩托级悬吊<br>前刹车系统",
          content: "激烈行进间使用刹车<br>仍能保持完美动作"
        },
        {
          title: "轻量化铝合金锻造轮毂",
          content: "重量轻且耐高压<br>转弯轻快灵活易操控"
        }
      ]
    };
  },

  mounted() {

    this.$nextTick(() => {
      this.mySwiper = new Swiper('.swiper2', {
        effect: 'fade',
        loop: true,
      })
      new Swiper('.swiper1', {
        slidesPerView: "auto",
        spaceBetween: 0,
        loop: true,
        navigation: {
          nextEl: '.image_9',
          prevEl: '.image_8',
        },

      })
    })

  },

  methods: {
    sclick(index) {
      this.mySwiper.slideToLoop(Number(index), 1000, false)
    }
  },
};
</script>

<style scoped>
.swiper1 .swiper-slide {
  width: auto !important;
}

.swiper2 {
  width: 100%;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section_1 {
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361023514828977.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_2 {
  padding: 7px 0 6px;
  position: relative;
}

.image-wrapper {
  padding: 167px 0 98px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361024301077088.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1511px;
}

.image {
  width: 685px;
  height: 712px;
}

.image_1 {
  width: 360px;
  height: 62px;
  position: absolute;
  left: 360px;
  top: 395px;
}

.image_2 {
  width: 270px;
  height: 48px;
  position: absolute;
  left: 360px;
  bottom: 437px;
}

.section_3 {
  padding: 25px 359px;
  background-color: #f6f6f6;
}

.text_5 {
  margin: 5px 0 6px;
  color: #000;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 29px;
}

.text-wrapper {
  padding: 11px 0 10px;
  border-radius: 20px;
  width: 140px;
  height: 40px;
  border: solid 2px #000000;
}

.text_6 {
  color: #000;
  font-size: 16px;
  font-family: 'Albbr';
  line-height: 15px;
}

.text-wrapper_1 {
  margin-left: 31px;
  padding: 13px 0 12px;
  background-color: #007aff;
  border-radius: 20px;
  width: 140px;
  height: 40px;
}

.text_7 {
  color: #ffffff;
  font-size: 16px;
  font-family: 'Albbr';
  line-height: 15px;
}

.section_4 {
  position: relative;
}

.image_3 {
  width: 1920px;
  height: 1080px;
}

.group_5 {
  
  position: absolute;
  left: 361px;
  top: 50%;
  transform: translateY(-50%);
}

.group_5 span{
  display: block;
}
.text_8 {
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 60px;
}

.text_9 {
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 60px;
}

.text_10 {
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 60px;
}

.text_11 {
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 60px;
}

.text_12 {
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 60px;
}

.text_13 {
  color: #ffffff;
  font-size: 48px;

  font-weight: 700;
  line-height: 60px;
}

.section_5 {
 position: relative;
}

.section_6 {
  padding: 348px 301px 108px 359px;
  background-color: #00000080;
}

.text_14 {
  align-self: center;
  color: #ffffff;
  font-size: 72px;
  font-family: 'Albb';
  line-height: 72px;
  letter-spacing: 3.6px;
}

.group_6 {
  margin-top: 259px;
}

.text_15 {
  color: #ffffff;
  font-size: 72px;
  font-family: 'Albb';
  line-height: 72px;
}

.text_16 {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 28px;
}

.text_17 {
  margin-top: 8px;
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}

.group_9 {
  margin-left: 196px;
}

.text_18 {
  color: #ffffff;
  font-size: 72px;
  font-family: 'Albb';
  line-height: 72px;
}

.text_19 {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 30px;
}

.text_20 {
  margin-top: 9px;
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}

.group_11 {
  margin-left: 187px;
}

.text_21 {
  color: #ffffff;
  font-size: 72px;
  font-family: 'Albb';
  line-height: 72px;
}

.text_22 {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 28px;
}

.text_23 {
  margin-top: 8px;
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}

.group_13 {
  margin-left: 168px;
  margin-top: 4px;
}

.text_24 {
  color: #ffffff;
  font-size: 72px;
  font-family: 'Albb';
  line-height: 72px;
}

.text_25 {
  margin-top: 5px;
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}

.section_7 {
  margin-top: 119px;
  padding: 20px 0 20px 6px;
  align-self: center;
  background-color: #ffffff;
  border-radius: 35px;
  width: 300px;
}

.image_6 {
  width: 30px;
  height: 30px;
}

.text_26 {
  margin-left: 18px;
  margin-bottom: 3px;
  color: #000000;
  font-size: 25px;
  font-family: 'Albbm';
  line-height: 25px;
  letter-spacing: 1.25px;
}

.section_8 {
  margin-top: 133px;
  padding: 200px 143px 206px;
  align-self: center;
  filter: drop-shadow(0px 14px 35px #00000030);
  border-radius: 50px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361018104598062.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1440px;
}

.group_14 {
  margin-right: 123px;
}

.text_27 {
  color: #ffffff;
  font-size: 60px;
  font-family: 'Albb';
  line-height: 72px;
}

.text_28 {
  color: #ffffff;
  font-size: 60px;
  font-family: 'Albb';
  line-height: 72px;
}

.group_15 {
  margin-top: 42px;
  line-height: 36px;
}

.text_29 {
  color: #ffffff;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  line-height: 36px;
}

.text_30 {
  color: #ffffff;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  line-height: 36px;
}

.text_31 {
  color: #ffffff;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  line-height: 36px;
}

.group_16 {
  margin-top: 92px;
  align-self: center;
}

.section_9 {
  padding: 84px 75px 412px;
  flex: 1 1 680px;
  filter: drop-shadow(0px 14px 35px #00000030);
  border-radius: 50px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361020099974670.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 680px;
  height: 780px;
}

.text_32 {
  color: #000;
  font-size: 60px;
  font-family: 'Albb';
  line-height: 72px;
}

.text_33 {
  color: #000;
  font-size: 60px;
  font-family: 'Albb';
  line-height: 72px;
}

.text_34 {
  margin-top: 33px;
  color: #000;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  line-height: 36px;
  width: 467px;
}

.section_10 {
  margin-left: 80px;
  padding: 83px 44px 412px;
  flex: 1 1 680px;
  filter: drop-shadow(0px 14px 35px #00000030);
  border-radius: 50px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361021006779807.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 680px;
  height: 780px;
}

.text_35 {
  color: #000;
  font-size: 60px;
  font-family: 'Albb';
  line-height: 72px;
  margin-left: 25px;
}

.text_36 {
  color: #000;
  font-size: 60px;
  font-family: 'Albb';
  line-height: 72px;
}

.text_37 {
  margin-left: 30px;
  margin-top: 34px;
  color: #000;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  line-height: 36px;
  width: 481px;
}

.text_38 {
  margin-left: 240px;
  margin-top: 205px;
  align-self: flex-start;
  color: #000;
  font-size: 72px;
  font-family: 'Albb';
  line-height: 68px;
}

.group_19 {
  margin-top: 73px;
  position: relative;
}

.image_5 {
  width: 180px;
  height: 181px;
}

.image_8 {
  flex-shrink: 0;
  align-self: center;
  position: relative;
  z-index: 99;
}

.group_20 {
  margin-left: 60px;
  flex: 1 1 auto;
  width: 100%;
}

.section_11 {
  padding: 68px 0 546px;

  border-radius: 50px;
  width: 600px;
  height: 750px;
  position: relative;
}

.image_7 {

  border-radius: 50px;
  width: 600px;
  height: 750px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.group_51 {
  margin-left: 47px;
  /* width: 314px; */
  position: relative;
}

.text_39 {
  align-self: flex-start;
  color: #000;
  font-size: 48px;
  font-family: 'Albb';

}

.group_21 {
  margin-top: 20px;
  line-height: 36px;
}

.text_40 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_41 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.section_12 {
  margin-left: 41px;
  padding: 67px 0 546px;
  filter: drop-shadow(0px 14px 35px #00000030);
  border-radius: 50px;
  width: 600px;
  height: 750px;
  position: relative;
}

.group_53 {
  margin-left: 48px;
  width: 293px;
  position: relative;
}

.text_42 {
  margin-left: 4px;
  color: #000;
  font-size: 48px;
  font-family: 'Albb';
  line-height: 45px;
}

.group_22 {
  margin-top: 20px;
  line-height: 36px;
}

.text_43 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_44 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.section_13 {
  margin-left: 41px;
  padding: 67px 0 284px 46px;
  filter: drop-shadow(0px 14px 35px #00000030);
  border-radius: 50px 0px 0px 50px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361016946747019.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 750px;
}

.text_45 {
  color: #000;
  font-size: 48px;
  font-family: 'Albb';
  line-height: 45px;
}

.text_46 {
  margin-top: 27px;
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 24px;
}

.image_9 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  margin: auto;
}

.image_10 {
  margin-top: 224px;
  align-self: center;
  width: 1920px;
  height: 750px;
}

.text_47 {
  margin-top: 78px;
  align-self: center;
  color: #000;
  font-size: 72px;
  font-family: 'Albb';
  line-height: 67px;
}

.group_24 {
  margin-top: 73px;
  align-self: center;
  width: 1172px;
}

.group_25 {
  padding-left: 10px;
}

.text_48 {
  color: #000;
  font-size: 30px;
  font-family: 'Albb';
  line-height: 29px;
}

.text_49 {
  color: #000;
  font-size: 30px;
  font-family: 'Albb';
  line-height: 29px;
}

.text_50 {
  color: #000;
  font-size: 30px;
  font-family: 'Albb';
  line-height: 29px;
}

.text_51 {
  margin-left: 7px;
  color: #000;
  font-size: 30px;
  font-family: 'Albb';
  line-height: 29px;
}

.group_27 {
  margin-right: 8px;
  margin-top: 20px;
}

.group_28 {
  line-height: 30px;
  text-align: center;
  width: 277px;
  height: 90px;
}

.text_52 {
  color: #747474;
  font-size: 18px;
  font-family: 'Albbr';
  line-height: 30px;
}

.text_53 {
  color: #747474;
  font-size: 18px;
  font-family: 'Albbr';
  line-height: 30px;
}

.group_29 {
  line-height: 30px;
  text-align: center;
  height: 90px;
  font-family: 'Albbr';
}

.group_29 span {
  display: block;
}

.text_54 {
  color: #747474;
  font-size: 18px;
  line-height: 30px;
}

.text_56 {
  color: #747474;
  font-size: 18px;
  line-height: 30px;
}

.text_58 {
  color: #747474;
  font-size: 18px;
  line-height: 30px;
}

.text-wrapper_2 {
  margin-top: 63px;
  padding: 11px 0 10px;
  align-self: center;
  border-radius: 20px;
  width: 140px;
  border: solid 2px #000000;
}

.text_61 {
  color: #000;
  font-size: 16px;
  font-family: 'Albbr';
  line-height: 15px;
  cursor: pointer;
}

.group_30 {
  margin-top: 138px;
}

.section_14 {
  padding: 153px 406px 118px 420px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361009855076836.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_32 {
  padding: 0 80px;
}

.text_62 {
  align-self: center;
  color: #ffffff;
  font-size: 72px;
  font-family: 'Albb';
  line-height: 69px;
}

.group_33 {
  margin-right: 14px;
  margin-top: 26px;
  padding-top: 29px;
  position: relative;
}

.image_11 {
  width: 920px;
  height: 554px;
}

.group_34 {
  line-height: 36px;
  text-align: center;
  position: absolute;
  left: 59px;
  right: 49px;
  top: 0;
}

.text_63 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_64 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_65 {
  align-self: center;
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 36px;
}

.text_66 {
  margin-top: 23px;
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}

.group_35 {
  margin-top: 75px;
}

.section_15 {
  background-color: #ffffff;
  width: 100px;
  height: 6px;
  cursor: pointer;
}

.section_16 {
  margin-left: 39px;
  background-color: #ffffff5c;
  width: 100px;
  height: 6px;
  cursor: pointer;
}

.section_17 {
  padding: 126px 0 83px;
  background-color: #f6f6f6;
}

.text_67 {
  color: #000;
  font-size: 42px;
  font-family: OPPOSans;
  line-height: 40px;
}

.text_68 {
  margin: 3px 0;
  color: #000;
  font-size: 42px;
  font-family: OPPOSans;
  line-height: 40px;
}

.group_37 {
  margin-top: 67px;
  padding-top: 388px;
  width: 633px;
  position: relative;
}

.text-wrapper_3 {
  padding: 127px 0 11px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618361009834019269.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 633px;
}

.text_69 {
  color: #89bffa;
  font-size: 28px;
  font-family: OPPOSans;
  line-height: 28px;
}

.image_12 {
  width: 460px;
  height: 500px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.group_38 {
  margin-top: 32px;
  width: 336px;
}

.group_39 {
  padding-bottom: 31px;
}

.image_13 {
  width: 51px;
  height: 51px;
}

.image_16 {
  width: 50px;
  height: 50px;
}

.text-wrapper_4 {
  padding: 26px 0;
  border-radius: 46px;
  width: 332px;
  border: solid 4px #0a0306;
}

.text_70 {
  color: #000;
  font-size: 32px;
  font-family: OPPOSans;
  line-height: 32px;
}

.image-wrapper_1 {
  padding: 179px 0 178px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279439091640877.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.image_18 {
  width: 1720px;
  height: 723px;
}

.section_18 {
  padding: 90px 96px 140px 100px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016316207594965.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_40 {
  padding-bottom: 31px;
  border-bottom: solid 1px #ffffff;
}

.image_19 {
  width: 191px;
  height: 31px;
}

.group_41 {
  margin: 7px 0 6px;
}

.text_71 {
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
  line-height: 18px;
}

.text_72 {
  margin-left: 47px;
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 17px;
}

.text_73 {
  margin-left: 48px;
  color: #ffffff;
  font-size: 18px;

  font-weight: 700;
  line-height: 17px;
}

.group_42 {
  margin-top: 48px;
}

.group_44 {
  width: 260px;
}

.image_20 {
  width: 260px;
  height: 22px;
}

.group_45 {
  margin-top: 15px;
  line-height: 26px;
}

.text_74 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.text_75 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.text_76 {
  color: #ffffff;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.image_21 {
  margin-right: 4px;
  width: 100px;
  height: 100px;
}

.text_77 {
  margin-top: 29px;
  align-self: flex-start;
  color: #ffffff;
  font-size: 14px;

  line-height: 14px;
}

.group_46 {
  margin-top: 13px;
}

.group_47 {
  margin-top: 23px;
  line-height: 13px;
  height: 13px;
}

.text_78 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_79 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_80 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_81 {
  color: #ffffff;
  font-size: 12px;

  line-height: 13px;
}

.text_82 {
  color: #ffffff;
  font-size: 12px;

  font-weight: 700;
  line-height: 10px;
}

.group_48 {
  margin-right: 4px;
}

.image_29 {
  margin-left: 41px;
  width: 36px;
  height: 36px;
}

.image_30 {
  margin-left: 0;
}

.image_24 {
  margin-left: 41px;
  width: 40px;
  height: 33px;
}

.image_25 {
  margin-left: 41px;
  width: 41px;
  height: 34px;
}

.image_26 {
  margin-left: 41px;
  width: 30px;
  height: 36px;
}

.image_28 {
  margin-left: 41px;
  width: 39px;
  height: 36px;
}
</style>