<template>
  <div>
    <div class="flex-col section_16">
  <div class="flex-col space-y-26 group_34">
    <span class="text_69">大材小用，各尽骑用</span>
    <div class="group_35">
      <span class="text_70">
        每个车部件都来自摩企60年制造工艺沉淀，全面应用最新智能制造系统，
        <br />
      </span>
      <span class="text_71">
        从零件到整车，都在机器人工作站的标准化监控下作业，高效且有品质保障。
        <br />
      </span>
    </div>
  </div>
  <div class="flex-col items-center space-y-23 group_36">
    <span class="text_72">摩托车级液压减震</span>
    <span class="text_73">
      KYMCO大排量踏板摩托车同材铬钼钢管，经过160万次应力及SHOCK测试，车身具备更大的强度和刚性。
    </span>
  </div>
  <div class="flex-row justify-center space-x-39 group_37">
    <div class="section_18" @click="sclick(0)"><!----></div>
    <div class="section_17" @click="sclick(1)"><!----></div>
  </div>
</div>
  </div>
</template>

<script>
export default {
  name: 'Vue3Ionex',
  props:["mySwiper"],
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    sclick(index){
      this.mySwiper.slideToLoop(Number(index) , 1000, false)
    }
  },
};
</script>

<style scoped>
.section_16 {
  padding: 123px 406px 118px 420px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16623580004436769791.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.space-y-26 > *:not(:first-child) {
  margin-top: 26px;
}
.group_34 {
  align-self: center;
  width: 812px;
}
.text_69 {
  align-self: center;
  color: #ffffff;
  font-size: 72px;
  font-family: 'Albb';
  line-height: 69px;
}
.group_35 {
  line-height: 36px;
  text-align: center;
}
.text_70 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}
.text_71 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}
.space-y-23 > *:not(:first-child) {
  margin-top: 23px;
}
.group_36 {
  margin-top: 509px;
}
.text_72 {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 36px;
}
.text_73 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}
.space-x-39 > *:not(:first-child) {
  margin-left: 39px;
}
.group_37 {
  margin-top: 75px;
}
.section_17 {
  background-color: #ffffff;
  width: 100px;
  height: 6px;
}
.section_18 {
  background-color: #ffffff5c;
  width: 100px;
  height: 6px;
}
</style>